import React, { Component } from 'react';

import UserInformation from '../user-information';
import './customer-page.css';
import Navigation from "../navigation";

export default class CustomerPage extends Component {

    render() {
        return (
            <div className="customer-page_flex full-width">
                <div>
                    <Navigation/>
                </div>
                <div className="customer-page_container">

                    <UserInformation/>

                </div>
            </div>
        );
    }
}


