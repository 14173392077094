import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './navigation.css';


const ADDRESS_ANNA = process.env.REACT_APP_ADDRESS_ANNA;

function isIncludes() {
    const linkAddress = window.location.toString();
    const substring = 'anna';
    return linkAddress.includes(substring);
}

const changeLogo = isIncludes() === false ? "https://491357-1551002-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/10/xverum-logo-white.png" : "./annalogo.png";


export default class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountName: localStorage.getItem('accountName')
        };
    }

    logOut () {
        localStorage.clear()
    }

    componentDidMount(){
        isIncludes()
    }

    render() {

        return (
        <div className="test-navigation_container">
            <header className="nav-header">
                <div className="header-logo">
                    <img style={{
                        width: '100px',
                    }} src= {changeLogo} />
                </div>
                <div className="nav-header_shift">
                    <p className="nav-name">{this.state.accountName}</p>
                    <p className="nav-header_shift-subtitle">other information</p>
                    <div className="nav-photo">
                        <img alt="user photo" src="https://www.clipartmax.com/png/full/103-1038880_user-rubber-stamp-female-user-icon.png" />
                    </div>
                </div>
            </header>
            <div className="test-navigation_container-inner">
                {/*<span>applications</span>*/}
                {localStorage.getItem('authorities') === 'ADMIN' ? <NavLink to="/" exact={true} >Dashboard</NavLink> : <div></div>}
                <NavLink to="/customer" >Account settings</NavLink>
                <NavLink to="/customer-usage">Account usage</NavLink>
                <NavLink to="/contacts">Contacts</NavLink>
                {localStorage.getItem('authorities') === 'ADMIN' ? <NavLink to="/create-customer" >Create customer</NavLink> : <div></div>}
                <NavLink to="/help-page">Help page</NavLink>
                <div>
                    <NavLink to="/login"
                        onClick={this.logOut}
                        style={{
                            opacity: '0.6',
                            color: "white",
                            display: 'block',
                            margin: 'auto',
                            marginTop: '40px',
                        }} type="button">Log out
                    </NavLink>
                </div>
            </div>
        </div>
        );
    }
}

