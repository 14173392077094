import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

ReactDOM.render(<App />,
    document.getElementById('root'));




// fetch('http://194.28.100.65:8280')
//     .then((res) => {
//         console.log('got response', res.status);
//     });

// function bla(keyRequest) {
//     fetch('http://194.28.100.65/login', keyRequest)
//         .then(data => data.json())
//         .then(keyResponse => fetch('http://194.28.100.65', {
//             method:'GET',
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 "Authorization": 'Bearer ' + keyResponse
//             }
//         }))
//         .then(data => data.json)
//         .then(userResponse => console.log(userResponse));
//     console.log("3");
// }
//
// function saveToken(token) {
//     console.log(token);
// }
//
//
//
// async function createGist() {
//     let usernameParams = {
//         'username': 'user',
//         'password': '1234',
//     };
//
//
//     // create a form from the given user params
//     let keyFormBody = [];
//     for (let property in usernameParams) {
//         let encodedKey = encodeURIComponent(property);
//         let encodedValue = encodeURIComponent(usernameParams[property]);
//         keyFormBody.push(encodedKey + "=" + encodedValue);
//     }
//     keyFormBody = keyFormBody.join("&");
//
//     let keyRequest = {
//         headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//         method: 'POST',
//         body: keyFormBody
//     };
//
//
//     fetch('http://194.28.100.65:8280/api/login', keyRequest)
//         .then(data => data.json())
//         .then(keyResponse => saveToken(keyResponse));
// }
//
// createGist();

//
