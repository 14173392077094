import React, {Component, useEffect} from 'react';

import CustomerList from '../customer-list';

import './admin-page.css';
import Navigation from "../navigation";
import MaterialTable from 'material-table';
import 'material-icons/iconfont/material-icons.css';
import {growl, GrowlScene} from "@crystallize/react-growl";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;
const ADDRESS_ANNA = process.env.REACT_APP_ADDRESS_ANNA;

function LogsTable() {

    useEffect(() => logData(), [1]);


    function logData() {
        const userName = localStorage.getItem('name');
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + userName + '/logs/', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => getDataSourceState(data));
    }


    function getDataSourceState(newdata) {
        const logs = newdata.logs;
        // console.log(logs.map(function(a) {
        //     return a.severity
        // }));
        setState((prevState) => {
            const getData = logs ;
            let data = [...prevState.data];
            data = getData;
            return { ...prevState, data };
        });
    }


    function testTest(data) {
        const dataID = data.map((a) => { return a.id });
        const userName = localStorage.getItem('name');
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'POST',
            body: JSON.stringify({"logs": dataID})
        };
        return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + userName + '/marklogsread', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'All fields must be filled!',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(logData)
    }





    const [state, setState] = React.useState({
        columns: [
            { title: 'Time', field: 'createDate' },
            // { title: 'Credit', field: 'credit' },
            // { title: 'Id', field: 'id' },
            { title: 'Info', field: 'message' },
            { title: 'Severity', field: 'severity' },
        ],
        data: [
        ],
        options: {
            header: true,
            sorting: true,
            toolbar: true,
            showTitle: true,
            columnsButton: false,
            filtering: true,
            // checkboxSelection: true,
            // actionsCellStyle: true,
            selection: true
        },
    });

    return (
        <MaterialTable
            title="Logs"
            columns={state.columns}
            data={state.data}
            options={state.options}
            editable={{}}
            actions={[
                {
                    tooltip: 'Remove All Selected',
                    icon: 'delete',
                    onClick: (evt, data) => testTest(data)
                }
            ]}
        />
    );
}




export default class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardTableLink: '',
            graphStyle: 1,
            anchorEl: null,
            anchorGR: null,
            selectedPeriod: 'current day'
        };
        this.getDataSourceState = this.getDataSourceState.bind(this);
    }



    getDataSourceState(data){
        this.setState({dashboardTableLink: data.dashboardTableLink});
    }

    customerSelect() {
        const userName = localStorage.getItem('name');
        const selectedCustomer = localStorage.getItem('selectedCustomerID');

        if (selectedCustomer === null || selectedCustomer === '') {
            return userName
        } else { return selectedCustomer }
    }




    createGraph(startData, endData) {
        // console.log('graph created');
        const graphStyle = localStorage.getItem('graphStyle');
        const frameStart = startData !== undefined ? startData : 'nowTFd';
        const frameEnd = endData !== undefined ? endData : 'now';

        // const dates = JSON.stringify({
        //     "frameStart": startData !== undefined ? startData : 'now%2Fd',
        //     "frameEnd": endData !== undefined ? endData : 'now',
        //     "host": ADDRESS_ANNA
        // });
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + this.customerSelect() + '/dashboard/' + ADDRESS_ANNA + '/' + graphStyle + '/' + frameStart + '/' + frameEnd, keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => this.getDataSourceState(data));
    }


    componentDidMount() {
        this.createGraph();
        this.customerSelect();
        const savedGS = localStorage.getItem('graphStyle');
        const saveGraphStyle = savedGS === null ? '' : localStorage.getItem('graphStyle');
    }




    handleCloseMulti = (startData, endData) => {
        // console.log(this.state.selectedPeriod);
        this.handleClose();
        this.createGraph(startData, endData);
        if (startData === 'nowTFd') {
            this.setState({selectedPeriod: 'current day'})
        } else if (startData === 'nowTFw') {
            this.setState({selectedPeriod: 'current week'})
        } else if (startData === 'now-1wTFw') {
            this.setState({selectedPeriod: 'last week'})
        } else if (startData === 'now-1MTFM') {
            this.setState({selectedPeriod: 'last month'})
        } else if (startData === 'now-3MTFM') {
            this.setState({selectedPeriod: 'last 3 month'})
        }
        else if (startData === 'nowTFM') {
            this.setState({selectedPeriod: 'current month'})
        } else {
            this.setState({selectedPeriod: 'last year'})
        }
    };

    handleClick = event => this.setState({ anchorEl: event.currentTarget });
    handleClose = () => this.setState({ anchorEl: null });

    handleClickGraph = event => this.setState({ anchorGR: event.currentTarget });
    handleCloseGraph = () => this.setState({ anchorGR: null });

    handleCloseMultiGraph = (value) => {
        this.handleCloseGraph();
        const saveGraphStyle = localStorage.setItem('graphStyle', value);
        return this.createGraph();
    };



    render() {
        const { anchorEl } = this.state;
        const { anchorGR } = this.state;
        let menuSelector = () => {if (localStorage.getItem('graphStyle') === '1') {
            return 'Overvew'} 
                else if (localStorage.getItem('graphStyle') === '2') {
                    return 'Sessions'}
                else if (localStorage.getItem('graphStyle') === '3') {
                    return 'Errors'}
                else {
                    return 'Customers Bar'}
        };
        const isStyle = localStorage.getItem('graphStyle') === null ? '' : localStorage.getItem('graphStyle');
        return (
            <div className="admin-information_container">
                <GrowlScene />
                <img alt="login" className="login-page_logo"
                     src="https://491357-1551002-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/10/xverum-logo-black-1.png" />
                <div className="admin-information_navigation">

                    <Navigation/>
                </div>
                <div style={{width: '100%'}} className="flex-column">
                    <div className="admin-information_subcontainer">
                        <div className="admin-information_customer-list">
                            <CustomerList/>
                        </div>
                    </div>







                    <div className="customer-usage_summary-plate timeframe-plate timeframe-plate_admin">
                        <div>
                            <p>Timeframe <span className="disabled">{this.state.selectedPeriod}</span></p>
                            <div>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                    Select period
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={() => this.handleCloseMulti('nowTFd', 'now')}>current day</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('nowTFw', 'now')}>current week</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('now-1wTFw', 'now-1wTFw')}>last week</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('nowTFM', 'now')}>current month</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('now-1MTFM', 'now-1MTFM')}>last month</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('now-3MTFM', 'now-1MTFM')}>last 3 month</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('now-1yTFy', 'now')}>last year</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>






                    <div className="user-information_cell user-information_cell-selection">
                        <span className="term">Graph display selection</span>
                        <div>
                            <Button style={{backgroundColor: '#61dafb',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '10px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClickGraph}>
                                {menuSelector()}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorGR}
                                keepMounted
                                open={Boolean(anchorGR)}
                                onClose={this.handleCloseGraph}>
                                <MenuItem onClick={() => this.handleCloseMultiGraph('1')}>Overvew</MenuItem>
                                <MenuItem onClick={() => this.handleCloseMultiGraph('2')}>Sessions</MenuItem>
                                <MenuItem onClick={() => this.handleCloseMultiGraph('3')}>Errors</MenuItem>
                                <MenuItem onClick={() => this.handleCloseMultiGraph('4')}>Customers Bar</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div className="graph-container">
                        <iframe className="admin-information_graphs" src={this.state.dashboardTableLink}></iframe>
                    </div>
                    <div>
                        <LogsTable/>
                    </div>
                </div>
            </div>

        );
    }
}