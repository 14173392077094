import React, { Component } from 'react';

import './customer-list.css';
import {growl} from "@crystallize/react-growl";


const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;


export default class CustomerList extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            name: localStorage.getItem('selectedName'),
            balance: localStorage.getItem('selectedCredit'),
            total: 0,
            selectedColor: false
        };
    }

    formatBytes (bytes, decimals) {
        // console.log(bytes);

        if (bytes < 0 ) return bytes =  Math.floor(bytes / 1073741824) + " GB";

        if (bytes === 0) return '0 GB';
        if (isNaN(parseFloat(bytes))) return bytes;
        if (typeof bytes === 'string') bytes = parseFloat(bytes);
        if (bytes === 0) return '0 GB';
        const dm = decimals + 1 || 3;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const k = 1000;
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    }


    createListCustomer() {
        const userName = localStorage.getItem('name');
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + userName + '/listchildreninfo', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => this.generateUserList(data));
    }

    generateUserList(data) {
        const list = data.sort(function(a, b) {
            return b.status - a.status
        });
        return this.setState({items: list})
    }


    handleList(item) {
        // console.log(item);
        const saveName = localStorage.setItem('selectedName', item.accountData.accountName);
        const saveCredit = localStorage.setItem('selectedCredit', item.balance);
        this.setState({
            name: item.accountData.accountName,
            balance: item.balance,
            total: item.total
        });
        return localStorage.setItem('selectedCustomerID', item.accountData.id);
    }



    componentDidMount() {
         this.createListCustomer();
    }



    render() {
        const list = this.state.items.map((item, index) => {
            return <div key={index} className={"customer-list_plate customer-list_plate" + item.status} onClick={() => this.handleList(item)}>
                        {/*{localStorage.getItem('selectedName') === item.accountData.accountName ? this.setState({selectedColor: }) }*/}
                        <span>Name:</span>
                        <p>{item.accountData.accountName}</p>
                        <div className="customer-list_plate-subcontainer">
                            <div>
                                <span>Balance:</span>
                                <p>{this.formatBytes(item.balance)}</p>
                            </div>
                            <div>
                                <span>Usage:</span>
                                <p>{this.formatBytes(item.total)}</p>
                            </div>
                        </div>
                    </div>
        });
        return (
            <div>
                <div className="customer-list_outer">
                    <div className="customer-list_container">
                        {list}
                    </div>
                    <div className="admin-information_details">
                        <p>Name: {this.state.name === undefined ? 'ADMIN' : this.state.name}</p>
                        <p>Balance: {this.formatBytes(this.state.balance)}</p>
                        <p>Usage: {this.formatBytes(this.state.total)}</p>
                    </div>
                </div>
            </div>
        );
    }
}


