import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import Navigation from "../navigation";
import 'material-icons/iconfont/material-icons.css';
import './contacts.css';
import { GrowlScene } from '@crystallize/react-growl';
import { growl } from '@crystallize/react-growl';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;



function customerSelect() {
    const userName = localStorage.getItem('name');
    const selectedCustomer = localStorage.getItem('selectedCustomerID');
    if (selectedCustomer === '') {
        return userName
    } else { return selectedCustomer }
}

let mobPhone = '';
let ofcPhone = '';


export default function Contacts() {

    useEffect(() => createContacts(), [1]);

    function createContacts() {
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/contacts/listinfo', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => getDataSourceState(data));
    }

     function getDataSourceState(newdata) {
        setState((prevState) => {
            const getData = newdata ;
            // console.log(newdata);
            let data = [...prevState.data];
            data = getData;
            return { ...prevState, data };
        });
    }


    function newMobPhone(data) {
        mobPhone = data;
    }
    function newOfcPhone(data) {
        ofcPhone = data;
    }


    const [state, setState] = React.useState({
        columns: [
            // { title: 'AN', field: 'accountName' },
            { title: 'FName', field: 'firstName' },
            { title: 'LName', field: 'lastName' },
            { title: 'Title', field: 'title' },
            { title: 'Office phone', field: 'officePhoneNumber',
                editComponent: (props) => (
                    <PhoneInput
                        // value={state.mobilePhoneNumber}
                        onChange={phone => newOfcPhone(phone)}
                    />
                ),
            },
            { title: 'Mob phone', field: 'mobilePhoneNumber',
                editComponent: (props) => (
                    <PhoneInput
                        // value={state.mobilePhoneNumber}
                        onChange={phone => newMobPhone(phone)}
                    />
                ),
            },
            { title: 'Email', field: 'email' },
            {
                title: 'Password', field: 'password',
                // render: rowData => <p>{rowData.password.split('').map(() => '*')}</p>,
                editComponent: props => (
                    <TextField
                        type="password"
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                    />)
            },
        ],
        data: [

        ],
        options: {
            // selection: {},
            pageSize: 10,

        },
    });

    function addContact(newData) {
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'POST',
            body: JSON.stringify(newData)
        };
        return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/contacts/add', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'All fields must be filled!',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
    }

    function removeContact(oldData) {
        let contactID = oldData.id;
        // console.log(oldData.id);
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'DELETE',
        };
        return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/contacts/' + contactID + '/remove', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
    }


    return (
        <div>
            <div className="contacts-container">
                <div>
                    <Navigation/>
                </div>
                <div  className="contacts-table_container">
                    <MaterialTable className="contacts-table"
                        title={<h4>{localStorage.getItem('selectedName') === null ? `Customer ID: ${localStorage.getItem('accountName')}` : `Customer ID: ${localStorage.getItem('selectedName')}`}</h4>}
                        columns={state.columns}
                        data={state.data}
                        options={state.options}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        setState((prevState) => {
                                            newData.mobilePhoneNumber = mobPhone;
                                            newData.officePhoneNumber = ofcPhone;
                                            const data = [...prevState.data];
                                            const testPrevState = console.log(prevState);
                                            const isOk = newData.mobilePhoneNumber && newData.email && newData.password !== undefined ?
                                                addContact(newData) : growl({
                                                message: 'Mobile, email, password fields must be filled!',
                                                type: 'error'
                                            });
                                            const testNewData = console.log(newData);
                                            const isPush = newData.mobilePhoneNumber && newData.email && newData.password !== undefined ?
                                                data.push(newData) : console.log('no');
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            removeContact(oldData);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                }),
                        }}
                    />
                    <GrowlScene />
                </div>
            </div>
            </div>
    );
}
