import React, { Component } from 'react';

import './help-page.css';
import Navigation from "../navigation";
import {growl} from "@crystallize/react-growl";

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;

export default class HelpPage extends Component {
    constructor() {
        super();
        this.state = {
            gwDomainUrl: '',
            gwUser: '',
            gwPassword: ''
        }
    }


    getTableData() {
        const userName = localStorage.getItem('name');
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + userName + '/gateway_info', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => this.setTableData(data));
    }

    setTableData(data) {
        for (var i in data){
            const setGwUser = this.setState({
                gwUser: data.gw_user
              });
            const setGwDomainUrl = this.setState({
                gwDomainUrl: data.gw_domain_url
            });
            const setGwPassword = this.setState({
                gwPassword: data.gw_password
            });
          };
    }


    componentDidMount() {
        this.getTableData();
   }




    render() {
        return (
            <div className="customer-page_flex full-width">
                <div>
                    <Navigation/>
                </div>
                <div className="customer-page_container help-container">
                    <h4>USAGE:</h4>
                    <h5 className="help-container_text">Here is an example how to use the <strong>AnnA</strong> service using curl tool:</h5>
                    <h6>When working in non sticky mode:</h6>
                    <ul>
                        <li>For non geo requests use <strong>-U user:password</strong>.<br/> The <strong>-U parameter</strong> should contain user (i.e. accountId) and password.</li>
                        <li>To force geo redirection use <strong>-U user___cc:password</strong>. <br/> Note that 3 underscore characters separate between user and two-characters country code. The system doesn&#39;t support <strong>uk</strong> country code, country code <strong>gb</strong> should be used instead.</li>
                    </ul>
                    <h6>To run a sticky session, add session_id and sticky session period:</h6>
                    <ul>
                        <li>Session id is a combination of chars and numbers positioned right after the 1st underscore (min 6 characters).</li>
                        <li>The session interval is positioned after the second underscore. Interval defined in seconds.</li>
                    </ul>
                    <h6>See the example below:</h6>

                    <div className="help-page_plates-container">
                        <div className="help-page_plate">
                            <b>Use case</b>
                            <p>non-geo</p>
                            <p>geo (e.g us)</p>
                        </div>
                        <div className="help-page_plate">
                            <b>Non sticky session</b>
                            <p>curl -m 20 -x ${this.state.gwDomainUrl} -U ${this.state.gwUser}: ${this.state.gwPassword} https://ifconfig.me</p>
                            <p>curl -m 20 -x trial.patur2.xyz___us:9012 -U ${this.state.gwUser}: ${this.state.gwPassword} https://ifconfig.me</p>
                        </div>
                        <div className="help-page_plate">
                            <b>Sticky session (e.g 120 sec)</b>
                            <p>curl -m 20 -x ${this.state.gwDomainUrl} -U ${this.state.gwUser} _asd123das_120: ${this.state.gwPassword} https://ifconfig.me</p>
                            <p>curl -m 20 -x ${this.state.gwDomainUrl} -U ${this.state.gwUser} _asd123das_120_us: ${this.state.gwPassword} https://ifconfig.me</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


