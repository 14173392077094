import React, { Component } from 'react';
import { GrowlScene } from '@crystallize/react-growl';
import { GoogleLogin } from 'react-google-login';
import { growl } from '@crystallize/react-growl';

import { useHistory } from "react-router-dom";

import './login-page.css';

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;
const ADDRESS_ANNA = process.env.REACT_APP_ADDRESS_ANNA;

function isIncludes() {
    const linkAddress = window.location.toString();
    const substring = 'anna';
    return linkAddress.includes(substring);
}

const changeLogo = isIncludes() === false ? "https://491357-1551002-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/10/xverum-logo-black-1.png" : "./annalogo.png";


export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            password: '',
            host: ADDRESS_ANNA,
            beVersion: 'unknown'
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.createGist = this.createGist.bind(this);
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    handleClick = e => {
        console.log("2")
        this.props.history.push("/customer");
    };

    saveToken(token) {
        localStorage.setItem('jwt', Object.values(token).join(''));
        console.log(token);
    }
    saveUserID(userid) {
        localStorage.setItem('name', Object.values(userid).join(''));
        localStorage.setItem('selectedCustomerID', Object.values(userid).join(''));
        console.log(userid);
    }
    saveAuthorities(data) {
        localStorage.setItem('authorities', Object.values(data.authorities).join(''));
        localStorage.setItem('accountName', Object.values(data.accountName).join(''));
        console.log(data.authorities);
        console.log(data.accountName);
    }


    fetchBEVersion() {
        let keyRequest = {
        headers: {'Content-Type': 'application/json'},
        method: 'GET'
        };

        fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/version', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
        .then(data => {
            this.setState({beVersion: data.name});
        })
    }

    async componentDidMount() {
        this.fetchBEVersion();
            setInterval(async () => {
                this.fetchBEVersion();
            }, 60000);
    }

    createGist() {


        let usernameParams = {
            'username': this.state.username,
            'password': this.state.password,
            'host': this.state.host
        };

        let keyFormBody = [];
        for (let property in usernameParams) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(usernameParams[property]);
            keyFormBody.push(encodedKey + "=" + encodedValue);
        }
        keyFormBody = keyFormBody.join("&");

        let keyRequest = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            method: 'POST',
            body: keyFormBody
        };

        fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/login', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
        .then(data => Object.values(data).join(''))
        .then(keyResponse => {
            fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/curuser', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + keyResponse
                }
            })
                .then(data => data.json())
                .then(userResponse => this.saveUserID(userResponse));
            this.saveToken(keyResponse);
            this.saveAuthorities(JSON.parse(atob(keyResponse.split('.')[1])));
            setTimeout(this.handleClick, 1000);
        })
        // .catch(error => console.log(error))
    }

    handleLogin = async googleData => {
        console.log(googleData)
        fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/googlelogin', {
            method: "POST",
            body: JSON.stringify({
                host: ANNA_HOST_KEY,
                token: googleData.tokenId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
        .then(data => Object.values(data).join(''))
        .then(keyResponse => {
            fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/curuser', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + keyResponse
                }
            })
                .then(data => data.json())
                .then(userResponse => this.saveUserID(userResponse));
            this.saveToken(keyResponse);
            this.saveAuthorities(JSON.parse(atob(keyResponse.split('.')[1])));
            console.log("1")
            setTimeout(this.handleClick, 1000);
        })
      }

  render() {

    return (
        <div>
            <div className="login-page_container-outer">

        <div className="login-page_container">
            <GrowlScene />
            <img className="login-page_logo" alt="user"
                 src={changeLogo} />
            <h1>Login</h1>
            <form>
                <label>
                    <input
                        type="text"
                        placeholder="username"
                        name="username"
                        value={this.state.username}
                        onChange={this.onInputchange}
                    />
                </label>
                <label>
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onInputchange}
                    />
                </label>
                <div onClick={this.createGist} className="login-page_submit">
                    <p>Login</p>
                </div>
                <GoogleLogin
                    clientId={"792247255873-sb9rbs7ll44arso3libfb3dgtra5j5al.apps.googleusercontent.com"}
                    disabled={false}
                    buttonText="Log in with Google"
                    onSuccess={this.handleLogin}
                    cookiePolicy={'single_host_origin'}
                />
            </form>
            <p className="login-page_signup">Not a member? <a>Sign up now</a></p>
            <p className="version-class" >frontend version 1.1.0</p>
            <p className="version-class" >backend version {this.state.beVersion}</p>
        </div>
        </div>
        </div>
    );
  }
}


