import React, { Component, useState, useMemo } from 'react';

import { GrowlScene } from '@crystallize/react-growl';
import { growl } from '@crystallize/react-growl';
import Select from 'react-select'
import countryList from 'react-select-country-list'

import Navigation from "../navigation";


import 'material-icons/iconfont/material-icons.css';
import './create-customer.css';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;


function CountrySelector() {
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        setCountry(value.value);
        setValue(value)
    };

    return <Select options={options} value={value} onChange={changeHandler} />
}

function setCountry(country) {
    const test = localStorage.setItem('region', country);
    return country;
}



    export default class CreateNewCustomer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                accountName: '',
                password: '',
                companyNumber: '',
                region: localStorage.getItem('region'),
                email: '',
                logo: 'logo',
                address: '',
                phoneNumber: '',
                website: '',
                description: '',
                notes: '',
                rolloverPolicy: 'SUBSCRIPTION',
                selectValue: '',
                anchorEl: null
            };

            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.clearData = this.clearData.bind(this);
        }


        createAccount(formData) {
            console.log(formData);
            let keyRequest = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
                },
                method: 'POST',
                body: JSON.stringify(formData)
            };
            return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + localStorage.getItem('name') + '/create', keyRequest) 
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 400)
                    throw growl({
                        message: data.statusText,
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
                .then(this.clearData)
        }

        handleSubmit() {
            if (this.state.accountName && this.state.password && this.state.email !== '') {
                return this.createAccount({
                    accountName: this.state.accountName,
                    password: this.state.password,
                    companyNumber: this.state.companyNumber,
                    region: localStorage.getItem('region'),
                    email: this.state.email,
                    logo: this.state.logo,
                    address: this.state.address,
                    phoneNumber: this.state.phoneNumber,
                    website: this.state.website,
                    description: this.state.description,
                    notes: this.state.notes,
                    rolloverPolicy: this.state.rolloverPolicy
                });
            } else growl({
                message: 'Account name, Password, Email required',
                type: 'warning'
            });
                // alert('Account name, Password, Email required')
        }

        handleInputChange(event) {
            // console.log(saveCountry);
            const target = event.target;
            const value = target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }

        clearData() {
            growl({
                message: 'User Created!',
                type: 'info'
            });
            this.setState({
                accountName: '',
                password: '',
                companyNumber: '',
                region: '',
                email: '',
                logo: 'logo',
                address: '',
                phoneNumber: '',
                website: '',
                description: '',
                notes: '',
                rolloverPolicy: 'Select Policy'
            })
        }

        // isOpened() {
        //     this.setState({isOpened: true})
        // }

        handleClick = event => this.setState({ anchorEl: event.currentTarget });
        handleClose = () => this.setState({ anchorEl: null });

        handleCloseMulti = (value) => {
            this.handleClose();
            return this.setState({rolloverPolicy: value})
        };



    render() {
        const { anchorEl } = this.state;
        return (
            <div className="customer-page_flex full-width">
                <div>
                    <Navigation/>
                </div>
                <div className="customer-page_container">
                    <h4>Create customer</h4>
                    <div>
                        <div className="user-information_container">
                            <img alt="user" className="user-image"
                                 src="https://www.clipartmax.com/png/full/103-1038880_user-rubber-stamp-female-user-icon.png" />
                            <form onSubmit={this.handleSubmit} className="user-information">
                                <GrowlScene />
                                {/*<span>Customer ID: </span>*/}
                                <div className="user-information_cell required">
                                    <span className="term">Account Name</span>
                                    <label>
                                        <input required
                                            type="text"
                                            placeholder="your account name"
                                            name="accountName"
                                            value={this.state.accountName}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell required">
                                    <span className="term">Email</span>
                                    <label>
                                        <input
                                            type="email"
                                            placeholder="your email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell required">
                                    <span className="term">Password</span>
                                    <label>
                                        <input required
                                            type="password"
                                            placeholder="your password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell required">
                                    <span className="term">Region</span>
                                    <CountrySelector/>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Address</span>
                                    <label>
                                        <input
                                            type="text"
                                            placeholder="your address"
                                            name="address"
                                            value={this.state.address}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Phone number</span>
                                    <label>
                                        <input
                                            type="tel"
                                            placeholder="your phone number"
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                {/*<div className="user-information_cell">*/}
                                {/*    <span className="term">Select Country</span>*/}
                                {/*    <CountrySelector/>*/}
                                {/*</div>*/}
                                <div className="user-information_cell">
                                    <span className="term">Website</span>
                                    <label>
                                        <input
                                            type="text"
                                            placeholder="your website"
                                            name="website"
                                            value={this.state.website}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Description</span>
                                    <label>
                                        <input
                                            type="text"
                                            placeholder="description"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Notes</span>
                                    <label>
                                        <input
                                            type="text"
                                            placeholder="your notes"
                                            name="notes"
                                            value={this.state.notes}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Company number</span>
                                    <label>
                                        <input
                                            type="text"
                                            placeholder="your company number"
                                            name="companyNumber"
                                            value={this.state.companyNumber}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                                <div className="user-information_cell">
                                    <span className="term">Credit Rollover Policy</span>
                                    <div>
                                        <Button style={{backgroundColor: '#61dafb',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: '10px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                            {this.state.rolloverPolicy}
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={() => this.handleCloseMulti('SUBSCRIPTION')}>SUBSCRIPTION</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('ONETIME')}>ONE-TIME</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                                    <button
                                        onClick={this.handleSubmit}
                                        style={{
                                        backgroundColor: '#61dafb', fontSize: '20px',
                                        padding: '5px 20px',
                                        margin: 'auto',
                                        marginTop: '40px',
                                        border: 'none',
                                        borderRadius: '20px',
                                        minWidth: '250px'
                                    }} type="button">Submit</button>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}


