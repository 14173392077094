import React from 'react';

import LoginPage from '../login-page';
import CustomerPage from '../customer-page';
import AdminPage from '../admin-page';
import CustomerUsage from '../customer-usage';
import Contacts from '../contacts';
import CreateNewCustomer from '../create-customer';
import HelpPage from '../help-page';
import AdminRoute from "../admin-route";
import PrivateRoute from "../private-route";


import './app.css';

import { BrowserRouter as Router, Route} from 'react-router-dom';

const App = () => {
  return (
      <Router>
        <div>
            <div className=" mb2 app-container">
                <Route path="/login" component={LoginPage} />
                <PrivateRoute path="/customer" component={CustomerPage} />
                <AdminRoute path="/" component={AdminPage} exact = { true } />
                <PrivateRoute path="/customer-usage" component={CustomerUsage} />
                <PrivateRoute path="/contacts" component={Contacts} />
                <AdminRoute path="/create-customer" component={CreateNewCustomer} />
                <PrivateRoute path="/help-page" component={HelpPage} />
            </div>
        </div>
      </Router>
  );
};

export default App;



