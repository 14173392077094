import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


import MaterialTable from 'material-table';
import 'material-icons/iconfont/material-icons.css';
import {growl} from "@crystallize/react-growl";


const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(name) {
    return {
        name,
        data: [],
    };
}

function customerSelect() {
    const userName = localStorage.getItem('name');
    const selectedCustomer = localStorage.getItem('selectedCustomerID');
    if (selectedCustomer === '') {
        return userName
    } else { return selectedCustomer }
}

function createDisabled(newData) {
    let keyRequest = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
        },
        method: 'POST',
        body: JSON.stringify(newData)
    };
    return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/adddisableddomain', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
}

function removeDisabled(oldData) {
    let keyRequest = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
        },
        method: 'POST',
        body: JSON.stringify({index: oldData.tableData.id})

    };
    return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/removedisableddomain', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    function addDisabled() {
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/disableddomains', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => getDataSourceState(data));
    }


    function getDataSourceState(newdata) {
        setState((prevState) => {
        const data = newdata ;
        return { ...prevState, data };
    });
    }


    const [state, setState] = React.useState({
        columns: [
            { title: '', field: 'address' },
        ],
        data:[],
        options: {
            // selection: {},
            pageSize: 5,
        },
    });

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {
                        setOpen(!open);
                        addDisabled();
                    }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <MaterialTable
                                        className="contacts-table"
                                        title=""
                                        columns={state.columns}
                                        data={state.data}
                                        options={state.options}
                                        editable={{
                                            onRowAdd: (newData) =>
                                                new Promise((resolve) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        setState((prevState) => {
                                                            const data = [...prevState.data];
                                                            data.push(newData);
                                                            createDisabled(newData);
                                                            return { ...prevState, data };
                                                        });
                                                    }, 600);
                                                }),
                                            onRowDelete: (oldData) =>
                                                new Promise((resolve) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        setState((prevState) => {
                                                            const data = [...prevState.data];
                                                            data.splice(data.indexOf(oldData), 1);
                                                            removeDisabled(oldData);
                                                            return { ...prevState, data };
                                                        });
                                                    }, 600);
                                                }),
                                        }}
                                    />
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({

        name: PropTypes.string.isRequired,

    }).isRequired,
};

const rows = [
    createData(''),
];

export default function RowDisabledDT() {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="Disabled domains">
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
