import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './customer-usage.css';
import Navigation from "../navigation";
import {growl} from "@crystallize/react-growl";

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;
const ADDRESS_ANNA = process.env.REACT_APP_ADDRESS_ANNA;

export default class CustomerUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            balance: 0,
            upload: 0,
            download: 0,
            total: 0,
            uploadTableLink: '',
            downloadTableLink: '',
            totalTableLink: '',
            anchorEl: null,
            anchorGR: null,
            selectedPeriod: 'current day'
        };
        this.getDataSourceState = this.getDataSourceState.bind(this);
    }

    handleCloseMulti = (startData, endData) => {
        // console.log(this.state.selectedPeriod);
        this.handleClose();
        this.createGraph(startData, endData);
        if (startData === 'now%2Fd') {
            this.setState({selectedPeriod: 'current day'})
        } else if (startData === 'now%2Fw') {
            this.setState({selectedPeriod: 'current week'})
        } else if (startData === 'now-1w%2Fw') {
            this.setState({selectedPeriod: 'last week'})
        } else if (startData === 'now-1M%2FM') {
            this.setState({selectedPeriod: 'last month'})
        } else if (startData === 'now-3M%2FM') {
            this.setState({selectedPeriod: 'last 3 month'})
        }
        else if (startData === 'now%2FM') {
            this.setState({selectedPeriod: 'current month'})
        } else {
            this.setState({selectedPeriod: 'last year'})
        }
    };

    handleClick = event => this.setState({ anchorEl: event.currentTarget });
    handleClose = () => this.setState({ anchorEl: null });

    handleClickGraph = event => this.setState({ anchorGR: event.currentTarget });
    handleCloseGraph = () => this.setState({ anchorGR: null });

    handleCloseMultiGraph = (value) => {
        this.handleCloseGraph();
        const saveGraphStyle = localStorage.setItem('graphStyleCustomer', value);
        return this.customerSelect();
    };


    formatBytes (bytes, decimals) {
        // console.log(bytes);

        if (bytes < 0 ) return bytes =  Math.floor(bytes / 1073741824) + " GB";
        if (bytes === 0) return '0 GB';
        if (isNaN(parseFloat(bytes))) return bytes;
        if (typeof bytes === 'string') bytes = parseFloat(bytes);
        if (bytes === 0) return '0 GB';

        const dm = decimals + 1 || 3;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const k = 1000;
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    }

    getDataSourceState(data){
        // console.log(data);
        this.setState({balance: data.balance < 0 ? `${'-' + this.formatBytes(data.balance)}` : this.formatBytes(data.balance)});
        this.setState({upload: data.upload !== -1 ? this.formatBytes(data.upload) : 'no data'});
        this.setState({download: data.download !== -1 ? this.formatBytes(data.download) : 'no data'});
        this.setState({total: data.total !== -1 ? this.formatBytes(data.total) : 'no data'});
        this.setState({uploadTableLink: data.uploadTableLink});
        this.setState({downloadTableLink: data.downloadTableLink});
        this.setState({totalTableLink: data.totalTableLink});
    }


    customerSelect() {
        const userName = localStorage.getItem('name');
        const selectedCustomer = localStorage.getItem('selectedCustomerID');

        if (selectedCustomer === null || selectedCustomer === '') {
            return userName
        } else { return selectedCustomer }
    }

    createGraph(startData, endData) {
        const graphStyle = localStorage.getItem('graphStyle');

        // const userName = localStorage.getItem('name');
        // const test = this.selectPeriod();
        // console.log(test);
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'POST',
            body: JSON.stringify({
                    "frameStart": startData !== undefined ? startData : 'now%2Fd',
                    "frameEnd": endData !== undefined ? endData : 'now',
                    "host": ADDRESS_ANNA,
                    "timezone": new Date().getTimezoneOffset()
            })
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + this.customerSelect() + '/usage', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            // .then(data => data.json())
            .then(data => this.getDataSourceState(data));
    }

    componentDidMount() {
        this.createGraph();
        const savedGS = localStorage.getItem('graphStyleCustomer');
        const saveGraphStyle = savedGS === null ? '' : localStorage.getItem('graphStyleCustomer');
    }

    render() {
        const { anchorEl } = this.state;
        const { anchorGR } = this.state;
        const isStyle = localStorage.getItem('graphStyleCustomer') === null ? '' : localStorage.getItem('graphStyleCustomer');
        return (
            <div>
                <div className="customer-usage_container">
                    <div>
                        <Navigation/>
                    </div>
                    <div className="customer-usage_subcontainer">
                        <h4>{localStorage.getItem('selectedName') === null ? `Customer ID: ${localStorage.getItem('accountName')}` : `Customer ID: ${localStorage.getItem('selectedName')}`}</h4>
                        <div className="customer-usage_summary">
                            <div className="customer-usage_summary-plate timeframe-plate">
                                <div>
                                    <p>Timeframe <span className="disabled">{this.state.selectedPeriod}</span></p>
                                    <div>
                                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                            Select period
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={() => this.handleCloseMulti('now%2Fd', 'now')}>current day</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now%2Fw', 'now')}>current week</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now-1w%2Fw', 'now-1w%2Fw')}>last week</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now%2FM', 'now')}>current month</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now-1M%2FM', 'now-1M%2FM')}>last month</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now-3M%2FM', 'now-1M%2FM')}>last 3 month</MenuItem>
                                            <MenuItem onClick={() => this.handleCloseMulti('now-1y%2Fy', 'now')}>last year</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-usage_summary-plate">
                                <p>Balance</p>
                                <p>{this.state.balance}</p>
                            </div>
                            {/*<div className="customer-usage_summary-plate">*/}
                            {/*    <p>Upload</p>*/}
                            {/*    <p>{this.state.upload}</p>*/}
                            {/*</div>*/}
                            {/*<div className="customer-usage_summary-plate">*/}
                            {/*    <p>Download</p>*/}
                            {/*    <p>{this.state.download}</p>*/}
                            {/*</div>*/}
                            {/*<div className="customer-usage_summary-plate">*/}
                            {/*    <p>Total</p>*/}
                            {/*    <p>{this.state.total}</p>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="user-information_cell user-information_cell-selection user-information_cell-customer-selection">*/}
                        {/*    <span className="term">Graph display selection</span>*/}
                        {/*    <div>*/}
                        {/*        <Button style={{backgroundColor: '#61dafb',*/}
                        {/*            border: 'none',*/}
                        {/*            borderRadius: '5px',*/}
                        {/*            padding: '10px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClickGraph}>*/}
                        {/*            {'Graph display' + ' ' + isStyle}*/}
                        {/*        </Button>*/}
                        {/*        <Menu*/}
                        {/*            id="simple-menu"*/}
                        {/*            anchorEl={anchorGR}*/}
                        {/*            keepMounted*/}
                        {/*            open={Boolean(anchorGR)}*/}
                        {/*            onClose={this.handleCloseGraph}>*/}
                        {/*            <MenuItem onClick={() => this.handleCloseMultiGraph('1')}>Graph display 1</MenuItem>*/}
                        {/*            <MenuItem onClick={() => this.handleCloseMultiGraph('2')}>Graph display 2</MenuItem>*/}
                        {/*            <MenuItem onClick={() => this.handleCloseMultiGraph('3')}>Graph display 3</MenuItem>*/}
                        {/*            <MenuItem onClick={() => this.handleCloseMultiGraph('4')}>Graph display 4</MenuItem>*/}
                        {/*        </Menu>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="traffic-plate_container">
                            <div className="traffic-plate">
                                <div>
                                    <p>Total in {this.state.selectedPeriod}</p>
                                    <p className="traffic-plate_num">{this.state.total}</p>
                                </div>
                                <iframe className="mini-graph" src={this.state.totalTableLink}>
                                </iframe>
                            </div>
                            <div className="traffic-plate">
                                <div>
                                    <p>Upload in {this.state.selectedPeriod}</p>
                                    <p className="traffic-plate_num">{this.state.upload}</p>
                                </div>
                                <iframe className="mini-graph" src={this.state.uploadTableLink}>
                                </iframe>
                            </div>
                            <div className="traffic-plate">
                                <div>
                                    <p>Download in {this.state.selectedPeriod}</p>
                                    <p className="traffic-plate_num">{this.state.download}</p>
                                </div>
                                <iframe className="mini-graph" src={this.state.downloadTableLink}>
                                </iframe>
                            </div>
                        </div>
                        {/*<h2>Usage statistic</h2>*/}
                        {/*<div className="customer-usage_graph">*/}
                        {/*    <iframe className="statistic-graph" src={this.state.uploadTableLink}>*/}
                        {/*    </iframe>*/}
                        {/*</div>*/}
                        {/*<h2>Summary</h2>*/}
                    </div>
                </div>
            </div>
        );
    }
}


