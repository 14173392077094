import React, {Component, useMemo, useState} from 'react';

import './user-information.css';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import RowDisabledDT from '../disabled-domains-table';
import RowEnabledDT from '../enabled-domains-table';
import { GrowlScene } from '@crystallize/react-growl';
import { growl } from '@crystallize/react-growl';

import MaterialTable from 'material-table';
import 'material-icons/iconfont/material-icons.css';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import countryList from "react-select-country-list";
// import Select from "react-select";

const ANNA_HOST_KEY = process.env.REACT_APP_ANNA_HOST_KEY;
const ANNA_BASE_KEY = process.env.REACT_APP_ANNA_BASE_KEY;


//country selector start

// function CountrySelector() {
//     const [value, setValue] = useState('')
//     const options = useMemo(() => countryList().getData(), [])
//
//     const changeHandler = value => {
//         setCountry(value.value);
//         setValue(value)
//     };
//
//     return <Select options={options} value={value} onChange={changeHandler} />
// }
// function setCountry(country) {
//     return country;
// }

//country selector end

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(name) {
    return {
        name,
        data: [],
    };
}

function customerSelect() {
    const userName = localStorage.getItem('name');
    // console.log('userName' + userName);
    const selectedCustomer = localStorage.getItem('selectedCustomerID');
    // console.log('selectedCustomer' + selectedCustomer);
    if (selectedCustomer === null) {
        return userName
    } else { return selectedCustomer }
}

function addWhiteList(newData) {
    // const userName = localStorage.getItem('name');
    let keyRequest = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
        },
        method: 'POST',
        body: JSON.stringify(newData)
    };
    return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/addwhite', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
}

function removeWhiteList(oldData) {
    // const userName = localStorage.getItem('name');
    let keyRequest = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
        },
        method: 'POST',
        body: JSON.stringify({index: oldData.tableData.id})

    };
    return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/removewhite', keyRequest)
        .then(data => {
            if (data.ok)
                return data.json();
            if (data.status === 401)
                throw growl({
                    message: 'User not found',
                    type: 'error'
                });
            else if (data.status === 500)
                throw growl({
                    message: 'Server Error',
                    type: 'error'
                });
            else
                throw growl({
                    message: 'Unknown error',
                    type: 'error'
                });
        })
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    function createWhiteList() {
        // const userName = localStorage.getItem('name');
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/whitelist', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(data => getDataSourceState(data));
    }

    function getDataSourceState(newdata) {
        setState((prevState) => {
            const data = newdata ;
            return { ...prevState, data };
        });
    }

    const [state, setState] = React.useState({
        columns: [
            { title: '', field: 'address' },
        ],
        data: [],
        options: {
            // selection: {},
            pageSize: 5,
        },
    });

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {
                        setOpen(!open);
                        createWhiteList();
                    }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <MaterialTable
                                        className="contacts-table"
                                                   title=""
                                                   columns={state.columns}
                                                   data={state.data}
                                                   options={state.options}
                                                   editable={{
                                                       onRowAdd: (newData) =>
                                                           new Promise((resolve) => {
                                                               setTimeout(() => {
                                                                   resolve();
                                                                   setState((prevState) => {
                                                                       const data = [...prevState.data];
                                                                       data.push(newData);
                                                                       addWhiteList(newData);
                                                                       return { ...prevState, data };
                                                                   });
                                                               }, 600);
                                                           }),
                                                       onRowDelete: (oldData) =>
                                                           new Promise((resolve) => {
                                                               setTimeout(() => {
                                                                   resolve();
                                                                   setState((prevState) => {
                                                                       const data = [...prevState.data];
                                                                       data.splice(data.indexOf(oldData), 1);
                                                                       removeWhiteList(oldData);
                                                                       return { ...prevState, data };
                                                                   });
                                                               }, 600);
                                                           }),
                                                   }}
                                    />
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

const rows = [
    createData(''),
];



export default class UserInformation extends Component {
    constructor(props) {

        super(props);
        this.state = {
            accountName: '',
            address: '',
            email: '',
            creditGb: 0,
            phoneNumber: '',
            accountNameRewritable: '',
            addressRewritable: '',
            passwordRewritable: '',
            emailRewritable: '',
            phoneNumberRewritable: '',
            addCredit: null,
            rolloverPolicy: '',
            selectValue: '',
            anchorEl: null,
            anchorElAcc: null,
            isDeactivated: ''
        };


        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCredit = this.handleCredit.bind(this);
        this.updateAccount = this.updateAccount.bind(this);

        this.createGist = this.createGist.bind(this);
        this.getDataSourceState = this.getDataSourceState.bind(this)
    }




    updateAccount(formData) {
        console.log(formData);
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'POST',
            body: JSON.stringify(formData)
        };
        return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/update', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'User data not changed',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            .then(this.informPopup)
    }


    informPopup() {
        growl({
            message: 'User data changed',
            type: 'info'
        });
    }

    handleSubmit() {
        const newAccountName = this.state.accountNameRewritable === '' ? this.state.accountName : this.state.accountNameRewritable;
        const newPassword = this.state.passwordRewritable === '' ? this.state.password : this.state.passwordRewritable;
        const newEmail = this.state.emailRewritable === '' ? this.state.email : this.state.emailRewritable;
        const newAddress = this.state.addressRewritable === '' ? this.state.address : this.state.addressRewritable;
        const newPhoneNumber = this.state.phoneNumberRewritable === '' ? this.state.phoneNumber : this.state.phoneNumberRewritable;


        return this.updateAccount({
                accountName: newAccountName,
                password: newPassword,
                email: newEmail,
                address: newAddress,
                phoneNumber: newPhoneNumber,
                rolloverPolicy: this.state.rolloverPolicy,
                isDeactivated: this.state.isDeactivated
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        // console.log(this.state.addCredit);
    }


    componentDidMount(){
        this.customerSelect();
        // this.createGist()
    }

    getDataSourceState(data){
        this.setState({accountName: data.accountName});
        this.setState({address: data.address});
        this.setState({email: data.email});
        this.setState({phoneNumber: data.phoneNumber});
        this.setState({creditGb: data.creditGb});
        this.setState({rolloverPolicy: data.rolloverPolicy});
        this.setState({isDeactivated: data.isDeactivated});
    }

    customerSelect() {
        const userName = localStorage.getItem('name');
        console.log('userName' + userName);
        const selectedCustomer = localStorage.getItem('selectedCustomerID');
        console.log('selectedCustomer' + selectedCustomer);
        if (selectedCustomer === null) {
            return this.createGist(userName)
        } else { return this.createGist(selectedCustomer) }
    }

    createGist(data) {
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'GET',
        };
        return fetch(ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + data + '/info', keyRequest)
            .then(data => {
                if (data.ok)
                    return data.json();
                if (data.status === 401)
                    throw growl({
                        message: 'User not found',
                        type: 'error'
                    });
                else if (data.status === 500)
                    throw growl({
                        message: 'Server Error',
                        type: 'error'
                    });
                else
                    throw growl({
                        message: 'Unknown error',
                        type: 'error'
                    });
            })
            // .then(data => data.json())
            .then(data => this.getDataSourceState(data));
    }


    handleCredit() {
        const newCredit = this.state.addCredit;
        return this.addBalance(newCredit)
    }


    addBalance(credit) {
        const toNum = Number(credit);
        const test = this.state.creditGb + toNum;
        const addB = this.setState({creditGb: test});
        const toGB = test * 1073741824;
        const addNewSelectedCredit = localStorage.setItem('selectedCredit', toGB);
        let keyRequest = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + localStorage.getItem('jwt'),
            },
            method: 'POST',
            body: JSON.stringify({balanceGb: credit})
        };
        return fetch( ANNA_HOST_KEY + ANNA_BASE_KEY + '/api/user/' + customerSelect() + '/addbalance', keyRequest)
            .then(data => data.json())
            // .then(data => console.log(data))
            .then(this.creditPopup)
    }


    creditPopup() {
        growl({
            message: 'Credit add',
            type: 'info'
        });
    }

    handleClick = event => this.setState({ anchorEl: event.currentTarget });
    handleClose = () => this.setState({ anchorEl: null });

    handleCloseMulti = (value) => {
        this.handleClose();
        return this.setState({rolloverPolicy: value})
    };

    handleClickAcc = event => this.setState({ anchorElAcc: event.currentTarget });
    handleCloseAcc = () => this.setState({ anchorElAcc: null });

    handleCloseMultiAcc = (value) => {
        this.handleCloseAcc();
        return this.setState({isDeactivated: value})
    };


    render () {
        const { anchorEl } = this.state;
        const { anchorElAcc } = this.state;
        return (
            <div className="user-information_container">
                <img className="user-image" alt="user-photo"
                     src="https://www.clipartmax.com/png/full/103-1038880_user-rubber-stamp-female-user-icon.png" />
                <form onSubmit={this.handleSubmit} className="user-information">
                    <GrowlScene />
                    <h4>{localStorage.getItem('selectedName') === null ? `Customer ID: ${localStorage.getItem('accountName')}` : `Customer ID: ${localStorage.getItem('selectedName')}`}</h4>
                    <div className="user-information_cell">
                        <span className="term">Email</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="email" name="emailRewritable" placeholder={this.state.email} value={this.state.emailRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.email}</span>}
                    </div>
                    <div className="user-information_cell">
                        <span className="term">password</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="password" name="passwordRewritable" placeholder={this.state.password} value={this.state.passwordRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.password}</span>}
                    </div>
                    <div className="user-information_cell">
                        <span className="term">First Name</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="text" name="accountNameRewritable" placeholder={this.state.accountName} value={this.state.accountNameRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.accountName}</span>}
                    </div>
                    <div className="user-information_cell">
                        <span className="term">Last Name</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="text" name="accountNameRewritable" placeholder={this.state.accountName} value={this.state.accountNameRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.accountName}</span>}
                    </div>
                    <div className="user-information_cell">
                        <span className="term">Address</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="text" name="addressRewritable" placeholder={this.state.address} value={this.state.addressRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.address}</span>}
                    </div>
                    <div className="user-information_cell">
                        <span className="term">Phone number</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="text" name="phoneNumberRewritable" placeholder={this.state.phoneNumber} value={this.state.phoneNumberRewritable} onChange={this.handleInputChange}/> : <span className="user-information_not-rewritable">{this.state.phoneNumber}</span>}
                    </div>
                        <div className="user-information_cell">
                        <span className="term">Whitelist IPs</span>
                        <TableContainer component={Paper}>
                            <Table aria-label="Enabled domains">
                                <TableBody>
                                    {rows.map((row) => (
                                        <Row key={row.name} row={row} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {localStorage.getItem('authorities') === 'ADMIN' ? <div className="user-information_cell">
                        <span className="term">Size agent pool</span>
                        {localStorage.getItem('authorities') === 'ADMIN' ? <input type="text" name="text" ></input> : <span className="user-information_not-rewritable"></span>}
                    </div> : <div></div>}
                    {localStorage.getItem('authorities') === 'ADMIN' ?
                        <div className="user-information_cell">
                            <span className="term">Enabled domains</span>
                            <RowEnabledDT/>
                        </div> : <div></div>}
                    {localStorage.getItem('authorities') === 'ADMIN' ?
                        <div className="user-information_cell">
                        <span className="term">Disabled domains</span>
                        <RowDisabledDT/>
                    </div> : <div></div>}
                    {/*{localStorage.getItem('authorities') === 'ADMIN' ?*/}
                    {/*    <div className="user-information_cell">*/}
                    {/*    <span className="term">Region</span>*/}
                    {/*    <CountrySelector/>*/}
                    {/*</div> : <span className="user-information_not-rewritable">{this.state.region}</span>}*/}
                    {localStorage.getItem('authorities') === 'ADMIN' ?
                    <div className="user-information_cell user-information_cell-row">
                        <div className="user-information_cell-row-inner">
                            <span className="term">Credit Rollover Policy</span>
                            <div>
                                <Button style={{backgroundColor: '#61dafb',
                                    border: 'none',
                                    borderRadius: '5px',
                                    padding: '10px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                    {this.state.rolloverPolicy === '' ? 'SUBSCRIPTION' : this.state.rolloverPolicy}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}>
                                    <MenuItem onClick={() => this.handleCloseMulti('SUBSCRIPTION')}>SUBSCRIPTION</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMulti('ONETIME')}>ONE-TIME</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div>
                            <span className="term">Change status</span>
                            <div>
                                <Button style={{backgroundColor: '#61dafb',
                                    border: 'none',
                                    borderRadius: '5px',
                                    padding: '10px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClickAcc}>
                                    {this.state.isDeactivated === true ? 'Deactivated' : 'Active'}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElAcc}
                                    keepMounted
                                    open={Boolean(anchorElAcc)}
                                    onClose={this.handleCloseAcc}>
                                    <MenuItem onClick={() => this.handleCloseMultiAcc(false)}>Active</MenuItem>
                                    <MenuItem onClick={() => this.handleCloseMultiAcc(true)}>Deactivate</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div> : <div className="user-information_cell"></div>}
                    <div className="user-information_cell">
                        <span className="term">Сredit balance</span>
                        <span className="user-information_not-rewritable">{this.state.creditGb} GB</span>
                    </div>
                    <div className="user-information_cell">
                        {localStorage.getItem('authorities') === 'ADMIN' ?
                            <div style={{display: 'flex'}}>
                                <input type="number" step="1" min="1" name="addCredit" className="credit-inp" placeholder="GB" value={this.state.addCredit} onChange={this.handleInputChange}/>
                                <button
                                onClick={this.handleCredit}
                                style={{
                                    backgroundColor: '#61dafb', fontSize: '16px',
                                    padding: '5px 20px',
                                    marginLeft: '10px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    minWidth: '150px'
                                }} type="button">Add credit</button>
                            </div> : <div></div>}
                    </div>
                    {localStorage.getItem('authorities') === 'ADMIN' ?
                        <button
                            onClick={this.handleSubmit}
                            style={{
                                backgroundColor: '#61dafb', fontSize: '20px',
                                padding: '5px 20px',
                                margin: 'auto',
                                marginTop: '40px',
                                border: 'none',
                                borderRadius: '20px',
                                minWidth: '250px'
                            }} type="button">Add changes</button> : <div></div>}
                </form>
            </div>
        )
    }
}

